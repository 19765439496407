import React, {useState, useCallback} from 'react';
import './App.scss';
import { useCookies } from 'react-cookie';

import { Link } from 'react-router-dom';

import home from './images/home.webp';
import home_mobile from './images/home_mobile.webp';

import _002 from './images/002.webp';
import _003 from './images/003.webp';
import _004 from './images/004.webp';
import simoplan from './images/simoplan.svg';
import logoNavbar from './images/logo_navbar.svg';
import logo_menu_mobile_open from './images/logo_menu_mobile_open.svg';
import thumbnail_video from './images/video.jpg';


import combustivel from './images/combustivel.webp';
import sustentabilidade from './images/sustentabilidade.svg';
import simoplan_box_box_13 from './images/simoplan_box_box_13.svg';
import background_particle from './images/background_6_1.webp';

import card_box_13_1 from './images/card_box_13_1.jpg';
import card_box_13_2 from './images/card_box_13_2.jpg';
import card_box_13_3 from './images/card_box_13_3.jpg';

import icone_menu from  './images/icone_menu.svg';
import icone_menu_close from  './images/cancel.svg';
import icone_1 from  './images/icone_01.svg';
import icone_2 from  './images/icone_02.svg';
import icone_3 from  './images/icone_03.svg';
import icone_4 from  './images/icone_04.svg';
import icone_5 from  './images/icone_05.svg';
import icone_6 from  './images/icone_06.svg';
import icone_7 from  './images/icone_07.svg';
import icone_8 from  './images/icone_08.svg';
import icone_9 from  './images/icone_09.svg';
import icone_10 from  './images/icone_10.svg';
import icone_11 from  './images/icone_11.svg';

import produto_0 from  './images/produto_0.jpg';
import produto_1 from  './images/produto_1.jpg';
import produto_2 from  './images/produto_2.jpg';
import produto_3 from  './images/produto_3.jpg';
import produto_4 from  './images/produto_4.jpg';
import produto_5 from  './images/produto_5.jpg';
import produto_10 from  './images/produto_10.jpg';

import produto_0_large from  './images/produto_0_large-min.webp';
import produto_1_large from  './images/produto_1_large-min.webp';
import produto_2_large from  './images/produto_2_large-min.webp';
import produto_3_large from  './images/produto_3_large-min.webp';
import produto_4_large from  './images/produto_4_large-min.webp';
import produto_5_large from  './images/produto_5_large-min.webp';
import produto_10_large from  './images/produto_10_large-min.webp';

import slide_11_1 from  './images/slide_11_2.png';
import slide_11_2 from  './images/slide_11_1.png';
import slide_11_3 from  './images/slide_11_3.png';
import slide_11_4 from  './images/slide_11_4.png';
import slide_11_5 from  './images/slide_11_5.png';

import localizacao_1 from  './images/localizacao_1.png';
import localizacao_2 from  './images/localizacao_2.png';
import localizacao_3 from  './images/localizacao_3.png';
import localizacao_4 from  './images/localizacao_4.png';
import localizacao_5 from  './images/localizacao_5.png';
import localizacao_6 from  './images/localizacao_6.png';
import localizacao_7 from  './images/localizacao_7.png';
import localizacao_8 from  './images/localizacao_8.png';
import localizacao_9 from  './images/localizacao_9.png';

import planta_1 from  './images/planta_01.webp';
import planta_2 from  './images/planta_02.webp';
import planta_3 from  './images/planta_03.webp';
import planta_4 from  './images/planta_04.webp';
import planta_5 from  './images/planta_05.webp';
import planta_6 from  './images/planta_06.webp';
import planta_9 from  './images/planta_09.webp';
import planta_10 from  './images/planta_10.webp';
import planta_11 from  './images/planta_11.webp';
import planta_12 from  './images/planta_12.webp';

import whatsapp from  './images/whatsapp.png';

import video1 from  './video/abertura.mp4';
import video2 from  './video/apresentacao.mp4';

import Carousel from 'react-elastic-carousel';
import Form from './Screen/Formulario';


function Home() {

  const [menuMobile, setMenuMobile] = useState(false);
  const [zoomGallery, setZoomGallery] = useState(false);
  const [zoomGalleryActiveIndex, setZoomGalleryActiveIndex] = useState(0);

  const [cookies, setCookie] = useCookies(['accept']);
 
  function accept(newName) {
    setCookie('accept', newName);
  };

  const openMenu = ()=>{
      setMenuMobile(!menuMobile);
  }
  const scrollToEl = useCallback((id) => {
      const to = document.getElementById(id).offsetTop - 80;
      window.scroll({ top: to, behavior: 'smooth' });
      setMenuMobile(false);
  }, []);

  function handleZoomGallery(e){
    setZoomGalleryActiveIndex(e);
    console.log(e);
    setZoomGallery(true);
  }

  return (
    <div className="App">
      <header>
            <navbar className="navbar mobile container">
                <div className="header_navbar">
                    <img className="navbar_logo" src={logoNavbar} alt="Logo Stil Navbar" />
                    <img src={icone_menu} onClick={openMenu} alt="Menu" />
                </div>
                <div className={`links ${menuMobile ? 'active': ''}`}>
                    <div className="links_header">
                        <img className="navbar_logo" src={logo_menu_mobile_open} alt="Logo Stil Navbar" />
                        <img src={icone_menu_close} onClick={openMenu} alt="Menu aberto" />
                    </div>
                    <ul className={`links_navbar`}>
                        <li onClick={() => scrollToEl('localizacao')}>Localização</li>
                        <li onClick={() => scrollToEl('lazer')}>Lazer</li>
                        <li onClick={() => scrollToEl('plantas')}>Plantas</li>
                        <li onClick={() => scrollToEl('profissionais')}>Profissionais </li>
                        <li onClick={() => scrollToEl('mapa')}>Mapa</li>
                        <li onClick={() => scrollToEl('cadastrese')} className="button_navbar">Cadastre-se</li>
                        <div className="rede_social">
                          <a href="https://www.instagram.com/simoplan_" rel="noreferrer" target="_blank">
                            <svg id="Componente_7_1" data-name="Componente 7 – 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                                <path id="Caminho_298" data-name="Caminho 298" d="M-221.978,946.675a3.144,3.144,0,0,1-3.132,3.129,3.143,3.143,0,0,1-3.133-3.167,3.141,3.141,0,0,1,3.152-3.108A3.14,3.14,0,0,1-221.978,946.675Zm4.617,3.929a3.5,3.5,0,0,1-3.764,3.8q-3.956.034-7.912,0a3.541,3.541,0,0,1-3.759-3.8q-.018-3.935,0-7.871a5.126,5.126,0,0,1,.14-1.129,3.192,3.192,0,0,1,2.91-2.574,9.027,9.027,0,0,1,1.02-.058c2.466-.005,4.933-.012,7.4.005a5.765,5.765,0,0,1,1.439.178,3.438,3.438,0,0,1,2.516,3.334c0,.065.005.131.005.2q0,2.029,0,4.055C-217.361,948.033-217.356,949.319-217.361,950.6Zm-2.849-3.936a4.886,4.886,0,0,0-4.854-4.825,4.838,4.838,0,0,0-4.877,4.868,4.832,4.832,0,0,0,4.807,4.774A4.837,4.837,0,0,0-220.21,946.668Zm1.271-5.063a1.113,1.113,0,0,0-1.125-1.122,1.128,1.128,0,0,0-1.127,1.127,1.109,1.109,0,0,0,1.153,1.132A1.1,1.1,0,0,0-218.94,941.6Zm11.37,5.145a17.5,17.5,0,0,1-17.5,17.5,17.5,17.5,0,0,1-17.5-17.5,17.5,17.5,0,0,1,17.5-17.5A17.5,17.5,0,0,1-207.57,946.75Zm-8.087-3.612c0-.38-.021-.761-.058-1.139a5.068,5.068,0,0,0-4.963-4.681c-2.924-.054-5.852-.045-8.776,0a4.94,4.94,0,0,0-4.65,3.348,8.2,8.2,0,0,0-.364,2.233c-.058,1.283-.016,2.571-.016,3.857h.009c0,1.4-.042,2.809.007,4.211a5.114,5.114,0,0,0,5.234,5.206q4.15.089,8.3,0a4.893,4.893,0,0,0,4.834-3.253,7.082,7.082,0,0,0,.422-2.34C-215.622,948.1-215.652,945.618-215.657,943.138Z" transform="translate(242.57 -929.25)" fill="#fff"/>
                            </svg>
                          </a>
                          <a href="https://www.facebook.com/simoplanimoveis" rel="noreferrer" target="_blank">
                            <svg id="Componente_6_1" data-name="Componente 6 – 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                                <path id="Caminho_299" data-name="Caminho 299" d="M104.17,929.25a17.5,17.5,0,1,0,17.5,17.5A17.5,17.5,0,0,0,104.17,929.25Zm4.342,17.519h-2.839v10.145H101.45V946.8H99.462v-3.6h1.976c0-.719-.035-1.4.009-2.081a8.568,8.568,0,0,1,.273-1.846,3.357,3.357,0,0,1,3.015-2.548c1.341-.144,2.7-.1,4.069-.138v3.525h-1.922c-.964,0-1.218.254-1.22,1.211V943.2h3.216C108.755,944.414,108.634,945.576,108.512,946.769Z" transform="translate(-86.67 -929.25)" fill="#fff"/>
                            </svg>
                          </a>
                      </div>
                    </ul>
                </div>
            </navbar>
            <navbar className="navbar desktop row container">
                <div className="navbar_logo">
                    <img src={logoNavbar} alt="Logo Stil Navbar" />
                </div>
                <ul className={`links_navbar`}>
                        <li onClick={() => scrollToEl('localizacao')}>Localização</li>
                        <li onClick={() => scrollToEl('lazer')}>Lazer</li>
                        <li onClick={() => scrollToEl('plantas')}>Plantas</li>
                        <li onClick={() => scrollToEl('profissionais')}>Profissionais </li>
                        <li onClick={() => scrollToEl('mapa')}>Mapa</li>
                        <li onClick={() => scrollToEl('cadastrese')} className="button_navbar">Cadastre-se</li>
                        <div className="rede_social">
                          <a href="https://www.instagram.com/simoplan_" rel="noreferrer" target="_blank">
                            <svg id="Componente_7_1" data-name="Componente 7 – 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                                <path id="Caminho_298" data-name="Caminho 298" d="M-221.978,946.675a3.144,3.144,0,0,1-3.132,3.129,3.143,3.143,0,0,1-3.133-3.167,3.141,3.141,0,0,1,3.152-3.108A3.14,3.14,0,0,1-221.978,946.675Zm4.617,3.929a3.5,3.5,0,0,1-3.764,3.8q-3.956.034-7.912,0a3.541,3.541,0,0,1-3.759-3.8q-.018-3.935,0-7.871a5.126,5.126,0,0,1,.14-1.129,3.192,3.192,0,0,1,2.91-2.574,9.027,9.027,0,0,1,1.02-.058c2.466-.005,4.933-.012,7.4.005a5.765,5.765,0,0,1,1.439.178,3.438,3.438,0,0,1,2.516,3.334c0,.065.005.131.005.2q0,2.029,0,4.055C-217.361,948.033-217.356,949.319-217.361,950.6Zm-2.849-3.936a4.886,4.886,0,0,0-4.854-4.825,4.838,4.838,0,0,0-4.877,4.868,4.832,4.832,0,0,0,4.807,4.774A4.837,4.837,0,0,0-220.21,946.668Zm1.271-5.063a1.113,1.113,0,0,0-1.125-1.122,1.128,1.128,0,0,0-1.127,1.127,1.109,1.109,0,0,0,1.153,1.132A1.1,1.1,0,0,0-218.94,941.6Zm11.37,5.145a17.5,17.5,0,0,1-17.5,17.5,17.5,17.5,0,0,1-17.5-17.5,17.5,17.5,0,0,1,17.5-17.5A17.5,17.5,0,0,1-207.57,946.75Zm-8.087-3.612c0-.38-.021-.761-.058-1.139a5.068,5.068,0,0,0-4.963-4.681c-2.924-.054-5.852-.045-8.776,0a4.94,4.94,0,0,0-4.65,3.348,8.2,8.2,0,0,0-.364,2.233c-.058,1.283-.016,2.571-.016,3.857h.009c0,1.4-.042,2.809.007,4.211a5.114,5.114,0,0,0,5.234,5.206q4.15.089,8.3,0a4.893,4.893,0,0,0,4.834-3.253,7.082,7.082,0,0,0,.422-2.34C-215.622,948.1-215.652,945.618-215.657,943.138Z" transform="translate(242.57 -929.25)" fill="#fff"/>
                            </svg>
                          </a>
                          <a href="https://www.facebook.com/simoplanimoveis" rel="noreferrer" target="_blank">
                            <svg id="Componente_6_1" data-name="Componente 6 – 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                                <path id="Caminho_299" data-name="Caminho 299" d="M104.17,929.25a17.5,17.5,0,1,0,17.5,17.5A17.5,17.5,0,0,0,104.17,929.25Zm4.342,17.519h-2.839v10.145H101.45V946.8H99.462v-3.6h1.976c0-.719-.035-1.4.009-2.081a8.568,8.568,0,0,1,.273-1.846,3.357,3.357,0,0,1,3.015-2.548c1.341-.144,2.7-.1,4.069-.138v3.525h-1.922c-.964,0-1.218.254-1.22,1.211V943.2h3.216C108.755,944.414,108.634,945.576,108.512,946.769Z" transform="translate(-86.67 -929.25)" fill="#fff"/>
                            </svg>
                          </a>
                      </div>
                    </ul>
            </navbar>
        </header>
      <section onClick={() => scrollToEl('localizacao')} className="box_1">
        <img className="d-none d-xl-block" src={home} alt="Altitude Cambui"/>
        <img className="d-xl-none" src={home_mobile} alt="Altitude Cambui"/>
      </section>
      <section  className="box_1_1">
        <div className="row">
          <div className="slide">
            <Carousel itemsToShow={1} className="slide d-xl-none">
                
                <div className="slide_item">
                    <img src={localizacao_2} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_3} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_4} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_5} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_6} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_7} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_8} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_9} />
                </div>
              </Carousel>
            <Carousel itemsToShow={3} className="slide d-none d-xl-block">
                <div className="slide_item">
                    <img src={localizacao_2} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_3} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_4} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_5} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_6} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_7} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_8} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_9} />
                </div>
                <div className="slide_item">
                    <img src={localizacao_1} />
                </div>
            </Carousel>
          </div>
        </div>
      </section>
      <section id="localizacao" className="box_2">
        <div class='row'>
           <div class='col-12 col-xl-6 column_1'>
            <h4>Excelente<br className='d-none d-xl-block'/> localização</h4>
            <p className="description">para levar mais praticidade para o seu dia a dia</p>
           </div>
           <div class='col-12 col-xl-6 column_2'>
            <p>Morar no Cambuí é estar perto<br className='d-none d-xl-block'/> de tudo em Campinas. Aqui você<br className='d-none d-xl-block'/> está a minutos de tudo em uma<br className='d-none d-xl-block'/> área cheia de possibilidades<br className='d-none d-xl-block'/> a serem exploradas. </p>
           </div>
        </div>
      </section>
      <section  className="box_3">
        <ul className="row itens">
         
          <li className="item">
            <img src={icone_2} />
            <p>CONVIVÊNCIA</p>
          </li>
        

          <li className="item">
            <img src={icone_4} />
            <p>MARIA ANTONIETA</p>
          </li>
          <li className="item">
            <img src={icone_1} />
            <p>TÊNIS CLUBE</p>
          </li>
          <li className="item">
            <img src={icone_5} />
            <p>LOJAS AMERICANAS</p>
          </li>
          <li className="item">
            <img src={icone_6} />
            <p>STARBUCKS</p>
          </li>
          <li className="item">
            <img src={icone_7} />
            <p>PADARIA RIVIERA</p>
          </li>
          <li className="item">
            <img src={icone_8} />
            <p>MORI SUSHI</p>
          </li>
          <li className="item">
            <img src={icone_9} />
            <p>OS MELHORES<br className='d-none d-xl-block'/> RESTAURANTES</p>
          </li>
          <li className="item">
            <img src={icone_3} />
            <p>DROGARIA SÃO PAULO</p>
          </li>
          <li className="item">
            <img src={icone_10} />
            <p>COLÉGIO<br className='d-none d-xl-block'/> PROGRESSO</p>
          </li>
          <li className="item">
            <img src={icone_11} />
            <p>CLUBE REGATAS</p>
          </li>
          
          
          <li className="item">
            <img src={icone_3} />
            <p>DROGASIL</p>
          </li>
        </ul>
      </section>
      <section id="galeria" className="box_4 right">
        <img src={background_particle} />
        <div class='row'>
          <div className="content">
             <h4 className="line_decoration right">
              {/* UM PROJETO<br className='d-none d-xl-block'/> COMPLETO, MODERNO<br className='d-none d-xl-block'/> E SUSTENTÁVEL */}
              UM PROJETO COMPLETO, MODERNO E SUSTENTÁVEL
             </h4>
          </div>
        </div>
      </section>
      <section  className="box_5">
        <img src={produto_10_large}/>
        <div className='legenda right'>PERSPECTIVA ILUSTRADA DOS ACESSOS</div>
      </section>
      <section  className="box_6">
        <div className="row">
            <Carousel itemsToShow={1} className="slide">
              <div className="slide_item">
                <video width="100%" height="auto" poster={thumbnail_video} controls>
                  <source src={video1} type="video/mp4"></source>
                </video>
              </div>
              <div className="slide_item">
                <video width="100%" height="auto" poster={thumbnail_video} controls>
                  <source src={video2} type="video/mp4"></source>
                </video>
              </div>
            </Carousel>
        </div>
      </section>
      <section  className="box_6_1">
      <img className="blackground d-none d-xl-block" src={background_particle}/>
        <div className="row">
          <div className="sustentabilidade">
                <img src={sustentabilidade} />
              </div>
          </div>
          <div class='row'>
            <div class='col-12 col-xl-6 column_1'>
                <h4>Liberdade Para<br className='d-none d-xl-block'/> estacionar</h4>
                <p>Apartamentos com <b>3 ou 4 vagas </b>
                <br className='d-none d-xl-block'/>de garagem + depósito privativo</p>
            </div>
            <div class='d-none d-xl-block col-12 col-xl-6 column_2'>
              <img src={combustivel} />
            </div>
          </div>
      </section>
      <section id="lazer" className="box_7 right">
        <div class='row'>
          <div className="description">
            <h4 className="line_decoration right">LAZER NO ROOFTOP</h4>
            <p>Projeto moderno e sofisticado<br className='d-none d-xl-block'/> Piscina aquecida com deck molhado<br className='d-none d-xl-block'/></p>
          </div>
        </div>
        <img src={_002} alt="LAZER NO ROOFTOP"/>
        <div className='legenda right'>PERSPECTIVA ILUSTRADA DA PISCINA</div>
      </section>
      <section  className="box_8 left">
        <div class='row'>
          <div className="description">
            <h4 className="line_decoration left">Espaço Gourmet</h4>
            <p>Para celebrar com a melhor<br className='d-none d-xl-block'/> vista panorâmica da cidade</p>
          </div>
        </div>
        <img src={_003} alt="Espaço Gourmet"/>
        <div className='legenda right'>PERSPECTIVA ILUSTRADA DO ESPAÇO GOURMET</div>
      </section>
      <section  className="box_8_1 right">
        <div class='row'>
          <div className="description">
            <h4 className="line_decoration right">Coworking</h4>
            <p>Pensado para a rotina de<br className='d-none d-xl-block'/> home office e encontros<br className='d-none d-xl-block'/> de negócios</p>
          </div>
        </div>
        <img src={_004} alt="Coworking"/>
        <div className='legenda right'>PERSPECTIVA ILUSTRADA DO COWORKING</div>
      </section>
      <section  className="box_9">
        <div className="row">
          <h4>Conheça mais do lazer</h4>
        </div>
          <div className="slide content-img">
            <div className="row">
              <Carousel itemsToShow={1} className="slide d-xl-none">
                <div className="slide_item"  onClick={()=>{handleZoomGallery(0)}}>
                    <img  src={produto_0} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(1)}}>
                    <img  src={produto_1} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(2)}}>
                    <img  src={produto_2} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(3)}}>
                    <img  src={produto_3} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(4)}}>
                    <img  src={produto_4} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(5)}}>
                    <img  src={produto_5} />
                </div>
                <div className="slide_item"   onClick={()=>{handleZoomGallery(6)}}>
                    <img  src={produto_10} />
                </div>
              </Carousel>
              
              <Carousel itemsToShow={4} className="slide d-none d-xl-block">

              <div className="slide_item"  onClick={()=>{handleZoomGallery(0)}}>
                    <img src={produto_0} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(1)}}>
                    <img src={produto_1} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(2)}}>
                    <img  src={produto_2} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(3)}}>
                    <img  src={produto_3} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(4)}}>
                    <img  src={produto_4} />
                </div>
                <div className="slide_item"  onClick={()=>{handleZoomGallery(5)}}>
                    <img  src={produto_5} />
                </div>
                <div className="slide_item"   onClick={()=>{handleZoomGallery(6)}}>
                    <img  src={produto_10} />
                </div>
               
              </Carousel>

              {zoomGallery ? 
                <div className="zoomGallery">
                    <div className="viewGallery">
                        <div className="close" onClick={()=>{setZoomGallery(false);}}>X</div>
                        <Carousel initialActiveIndex={zoomGalleryActiveIndex} className="slide">
                          <div className="slide_item">
                            <img  src={produto_0_large} />
                            <p>PERSPECTIVA ILUSTRADA DO BICICLETÁRIO</p>
                          </div>
                          <div className="slide_item">
                              <img  src={produto_1_large} />
                              <p>PERSPECTIVA ILUSTRADA DA PISCINA</p>
                          </div>
                          <div className="slide_item">
                              <img  src={produto_2_large} />
                              <p>PERSPECTIVA ILUSTRADA DO SOLARIUM</p>
                          </div>
                          <div className="slide_item">
                              <img  src={produto_3_large} />
                              <p>PERSPECTIVA ILUSTRADA DO FITNESS</p>
                          </div>
                          <div className="slide_item">
                              <img  src={produto_4_large} />
                              <p>PERSPECTIVA ILUSTRADA DA BRINQUEDOTECA</p>
                          </div>
                          <div className="slide_item">
                              <img  src={produto_5_large} />
                              <p>PERSPECTIVA ILUSTRADA DO ESPAÇO GOURMET</p>
                          </div>
                          <div className="slide_item">
                              <img  src={produto_10_large} />
                              <p>PERSPECTIVA ILUSTRADA DOS ACESSOS</p>
                          </div>
                        </Carousel>
                    </div>
                </div>
              : '' } 
          </div>
        </div>
      </section>
      <section id="plantas" className="box_10">
        <div className="row left">
          <h4 className="line_decoration right">Tenha liberdade<br className='d-none d-xl-block'/> para personalizar o<br className='d-none d-xl-block'/> seu apartamento</h4>
        </div>
        <div className="row">
          <Carousel itemsToShow={1} className="slide">
          <div className="slide_item">
                <img src={planta_9} />
            </div>
            <div className="slide_item">
                <img src={planta_10} />
            </div>
            <div className="slide_item">
                <img src={planta_11} />
            </div>
            <div className="slide_item">
                <img src={planta_12} />
            </div>
            <div className="slide_item">
                <img src={planta_1} />
                <div className='legenda right'>PERSPECTIVA ILUSTRADA DO LIVING - OPÇÃO DE PLANTA 1</div>
            </div>
            <div className="slide_item">
                <img src={planta_2} />
                <div className='legenda right'>PERSPECTIVA ILUSTRADA DO LIVING - OPÇÃO DE PLANTA 2</div>
            </div>
            <div className="slide_item">
                <img src={planta_3} />
                <div className='legenda right'>PERSPECTIVA ILUSTRADA DA SUÍTE - OPÇÃO DE PLANTA 2</div>
            </div>
            <div className="slide_item">
                <img src={planta_4} />
                <div className='legenda right'>PERSPECTIVA ILUSTRADA DO LIVING - OPÇÃO DE PLANTA 3</div>
            </div>
            <div className="slide_item">
                <img src={planta_5} />
                <div className='legenda right'>PERSPECTIVA ILUSTRADA DA SUÍTE- OPÇÃO DE PLANTA 3</div>
            </div>
            <div className="slide_item">
                <img src={planta_6} />
                <div className='legenda right'>PERSPECTIVA ILUSTRADA DO LIVING - OPÇÃO DE PLANTA 4</div>
            </div>
            {/* <div className="slide_item">
                <img src={planta_7} />
            </div>
            <div className="slide_item">
                <img src={planta_8} />
            </div> */}
            
          </Carousel>
        </div>
      </section>
      <section  className="box_11 left" id="profissionais">
        <div className="row">
          <h4 className="line_decoration left">Assinado por<br className='d-none d-xl-block'/> profissionais<br className='d-none d-xl-block'/> altamente<br className='d-none d-xl-block'/> renomados </h4>
        </div>
        <div className="row">
          <Carousel itemsToShow={1} className="slide d-xl-none">
            <div className="item">
                <img src={slide_11_1} alt="Fidélista"/>
                <h5>Projeto de<br className='d-none d-xl-block'/> arquitetura</h5>
                <p>Fidélista</p>
            </div>
            <div className="item">
                <img src={slide_11_2} alt="Elaine Carvalho"/>
                <h5>Projeto de<br className='d-none d-xl-block'/> Decoração</h5>
                <p>Elaine Carvalho</p>
            </div>
            <div className="item">
                <img src={slide_11_3} alt="Alexandre Furcolin"/>
                <h5>Projeto de<br className='d-none d-xl-block'/> paisagismo</h5>
                <p>Alexandre Furcolin</p>
            </div>
            <div className="item">
                <img src={slide_11_4} alt="Construção"/>
                <h5>Construção</h5>
                <p>Barros Pimentel</p>
            </div>
            <div className="item">
                <img src={slide_11_5} alt="Construção"/>
                <h5>Construção</h5>
                <p>Barros Pimentel</p>
            </div>
          </Carousel>
          
          <Carousel itemsToShow={4} className="slide d-none d-xl-block">
            <div className="item">
                <img src={slide_11_1} alt="Fidélista"/>
                <h5>Projeto de<br className='d-none d-xl-block'/> arquitetura</h5>
                <p>FIDELIS ASTA</p>
            </div>
            <div className="item">
                <img src={slide_11_2} alt="Elaine Carvalho"/>
                <h5>Projeto de<br className='d-none d-xl-block'/> Decoração</h5>
                <p>Elaine Carvalho</p>
            </div>
            <div className="item">
                <img src={slide_11_3} alt="Alexandre Furcolin"/>
                <h5>Projeto de<br className='d-none d-xl-block'/> paisagismo</h5>
                <p>Alexandre Furcolin</p>
            </div>
            <div className="item">
                <img src={slide_11_4} alt="Construção"/>
                <h5>Construção</h5>
                <p>Barros Pimentel</p>
            </div>
            <div className="item">
                <img src={slide_11_5} alt="Construção"/>
                <h5>Construção</h5>
                <p>Barros Pimentel</p>
            </div>
          </Carousel>
        </div>
          
      </section>
      <section id="mapa" className="box_12 right">
        <div class='row'>
          <h4 className="line_decoration right">Joaquim<br/> Novaes - 128</h4>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.381650268593!2d-47.05921728448775!3d-22.899289843361885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8cf4d67694151%3A0xabfa02ea38335ae0!2sR.%20Joaquim%20Novaes%2C%20128%20-%20Cambu%C3%AD%2C%20Campinas%20-%20SP%2C%2013015-140!5e0!3m2!1spt-BR!2sbr!4v1619094179431!5m2!1spt-BR!2sbr" width="100%" height="607" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
      </section>
      <section  className="box_13">
        <div class='row content'>
          <div class='col-12 col-xl-6 column_1'>
            <h4> Solidez,<br className='d-none d-xl-block'/> Experiência<br className='d-none d-xl-block'/> e Inovação </h4>
          </div>
          <div class='col-12 col-xl-6 column_2'>
            <img src={simoplan_box_box_13} />
          </div>
        </div>
        
        <div className="row cards">
          <div className="item">
            <img src={card_box_13_1} alt="Roberto Baldin Simionato"/>
            <h5>Roberto Baldin<br className='d-none d-xl-block'/> Simionato</h5>
            <p>+ de 270.000m² de obras civis e incorporações. Lançou projetos relevantes e inovadores para a cidade.</p>
          </div>
          <div className="item center">
            <img src={card_box_13_2} alt="Rafael Dammus Taiar Simionato"/>
            <h5>Rafael Dammus<br className='d-none d-xl-block'/> Taiar Simionato</h5>
            <p>Sócio da Simopark | PareBem (2ª maior empresa do setor no país, com operações em 16 estados) e gestor das propriedades imobiliárias do grupo há mais de 15 anos.</p>
          </div>
          <div className="item">
            <img src={card_box_13_3} alt="Carlos Augusto Amaral Paes de Barros"/>
            <h5>Carlos Augusto Amaral<br className='d-none d-xl-block'/> Paes de Barros</h5>
            <p>Sócio da Guepardo, importante loteadora no estado de São Paulo, fabricante de aeronaves no Texas (USA), dentre outros negócios.</p>
          </div>
        </div>        
      </section>
      <section id="cadastrese" className="box_13 left">
        <div class='row'>
          <h4 className="line_decoration left">CADASTRE-SE</h4>
          <Form/>
        </div>
      </section>
      <section  className="box_14">
        <div className="line"></div>
        <h3>VISITE NOSSO STAND: <a href="https://www.google.com/maps/place/R.+Joaquim+Novaes,+128+-+Centro,+Campinas+-+SP,+13015-140/@-22.8990633,-47.0570532,15z/data=!4m15!1m9!3m8!1s0x94c8cf4d66df2ae3:0x9676c678ef6dcfbf!2sR.+Joaquim+Novaes,+146+-+Centro,+Campinas+-+SP,+13015-140!3b1!8m2!3d-22.8991947!4d-47.0569525!14m1!1BCgIgAQ!3m4!1s0x94c8cf4d66df2ae3:0x9676c678ef6dcfbf!8m2!3d-22.8991947!4d-47.0569525" rel="noopener noreferrer" target="_blank">Rua Joaquim Novaes, 128 - Cambui - Campinas/SP</a></h3>
        <div class='row'>
          {/* <a className="politica" href={'/privacy'}>Política de Privacidade</a> */}
          {/* <Link to="/Privacy">Política de Privacidade</Link> */}
          <p>Mais informações:</p>
          <div className="numbers">
            <a href="tel:1933954640">(19) 3395.4640</a> <span>|</span> <a href="tel:19992938912">(19) 9 9293.8912</a>
          </div>
          <div className="info">
            <div className="item">
              <img src={simoplan} alt="realização simoplan"/>
            </div>
          </div>
        </div>
      </section>
     <footer>
        <div className="row">
         <p>Empreendimento Altitude Cambuí: Projeto aprovado em 19/11/2020, protocolo 2019/11/14316. Alvará de aprovação nº 718/2020 de 01/12/2020, Alvará de execução nº 217/2021 de 04/02/2021. Registro de incorporação R1 da matrícula nº 143.007 do 1º Oficial de Registro de Imóveis da Comarca de Campinas – Estado de São Paulo. Todas as imagens são ilustrativas e possuem sugestões de decoração. Os móveis e utensílios são de dimensões comerciais, não fazem parte integrante do contrato de compra e venda de imovel. Áreas comuns serão entregues decoradas equipadas conforme memorial descritivo. Os acabamentos serão entregues conforme memorial descritivo.</p>
        </div>
      </footer>
      <div className="whatsapp">
        <a href="https://api.whatsapp.com/send?phone=5519992938912">
          <img src={whatsapp}/>
        </a>
      </div>
      <div className={`privacy ${cookies.accept === 'yes' ? 'active' : ''}`}>
      {/* <div className={`privacy`}> */}
        <div className="row">
          <div className="frase-aviso">Este site deseja usar cookies para melhorar a sua experiência.</div>
          <div className="buttons">
            <div className="button aceitar" onClick={()=>{accept('yes')}}>Aceitar</div>
            {/* <a className="button saiba-mais" target="_blank" rel="noreferrer"  href={'Privacy.html'}>Política de Privacidade</a> */}
            <Link className="button saiba-mais" to="/Privacy">Política de Privacidade</Link>
          </div>
          </div>
      </div>
    </div>
  );
}

export default Home;
