import React, {useState, useCallback} from 'react';
import './App.scss';
import { useCookies } from 'react-cookie';

import simoplan from './images/simoplan.svg';
import logoNavbar from './images/logo_navbar.svg';
import logo_menu_mobile_open from './images/logo_menu_mobile_open.svg';

import icone_menu from  './images/icone_menu.svg';
import icone_menu_close from  './images/cancel.svg';
import whatsapp from  './images/whatsapp.png';

function Home() {

  const [menuMobile, setMenuMobile] = useState(false);
  const [zoomGallery, setZoomGallery] = useState(false);
  const [zoomGalleryActiveIndex, setZoomGalleryActiveIndex] = useState(0);

  const [cookies, setCookie] = useCookies(['accept']);
 
  function accept(newName) {
    setCookie('accept', newName);
  };

  const openMenu = ()=>{
      setMenuMobile(!menuMobile);
  }
  const scrollToEl = useCallback((id) => {
      const to = document.getElementById(id).offsetTop;
      window.scroll({ top: to, behavior: 'smooth' });
      setMenuMobile(false);
  }, []);

  function handleZoomGallery(e){
    setZoomGalleryActiveIndex(e);
    console.log(e);
    setZoomGallery(true);
  }

  return (
    <div className="App">
    <header>
        <navbar className="navbar mobile container">
            <div className="header_navbar">
                <img onClick={() => window.location.href = "/"} className="navbar_logo" src={logoNavbar} alt="Logo Stil Navbar" />
                <img src={icone_menu} onClick={openMenu} alt="Menu" />
            </div>
            <div className={`links ${menuMobile ? 'active': ''}`}>
                <div className="links_header">
                    <img className="navbar_logo" src={logo_menu_mobile_open} alt="Logo Stil Navbar" />
                    <img src={icone_menu_close} onClick={openMenu} alt="Menu aberto" />
                </div>
                <ul className={`links_navbar`}>
                    <li onClick={() => window.location.href = "/"}>Voltar para o site</li>
                </ul>
            </div>
        </navbar>
        <navbar className="navbar desktop row container">
            <div className="navbar_logo">
                <img onClick={() => window.location.href = "/"}  src={logoNavbar} alt="Logo Stil Navbar" />
            </div>
            <ul className={`links_navbar`}>
                    <li onClick={() => window.location.href = "/"}>Voltar para o site</li>
                </ul>
        </navbar>
    </header>

    <section  className="banner_agracimento">
        <div className="content_description">
            <h3>Agradecemos o seu contato</h3>
            <p>Em breve, você irá conhecer</p>
            <p>os detalhes do seu futuro lar!</p>
        </div>
    </section>

    <section  className="box_14">
        <div className="line"></div>
        <h3>VISITE NOSSO STAND: <a href="https://www.google.com/maps/place/R.+Joaquim+Novaes,+128+-+Centro,+Campinas+-+SP,+13015-140/@-22.8990633,-47.0570532,15z/data=!4m15!1m9!3m8!1s0x94c8cf4d66df2ae3:0x9676c678ef6dcfbf!2sR.+Joaquim+Novaes,+146+-+Centro,+Campinas+-+SP,+13015-140!3b1!8m2!3d-22.8991947!4d-47.0569525!14m1!1BCgIgAQ!3m4!1s0x94c8cf4d66df2ae3:0x9676c678ef6dcfbf!8m2!3d-22.8991947!4d-47.0569525" rel="noopener noreferrer" target="_blank">Rua Joaquim Novaes, 128 - Cambui - Campinas/SP</a></h3>
        <div class='row'>
            <a className="politica" href={'/privacy'}>Política de Privacidade</a>
            <p>Mais informações:</p>
            <div className="numbers">
            <a href="tel:1933954640">(19) 3395.4640</a> <span>|</span> <a href="tel:19992938912">(19) 9 9293.8912</a>
            </div>
            <div className="info">
            <div className="item">
                <img src={simoplan} alt="realização simoplan"/>
            </div>
            </div>
        </div>
    </section>
    
     <footer>
        <div className="row">
         <p>Empreendimento Altitude Cambuí: Projeto aprovado em 19/11/2020, protocolo 2019/11/14316. Alvará de aprovação nº 718/2020 de 01/12/2020, Alvará de execução nº 217/2021 de 04/02/2021. Registro de incorporação R1 da matrícula nº 143.007 do 1º Oficial de Registro de Imóveis da Comarca de Campinas – Estado de São Paulo. Todas as imagens são ilustrativas e possuem sugestões de decoração. Os móveis e utensílios são de dimensões comerciais, não fazem parte integrante do contrato de compra e venda de imovel. Áreas comuns serão entregues decoradas equipadas conforme memorial descritivo. Os acabamentos serão entregues conforme memorial descritivo.</p>
        </div>
      </footer>
      <div className="whatsapp">
        <a href="https://api.whatsapp.com/send?phone=5519992938912">
          <img src={whatsapp}/>
        </a>
      </div>
    </div>
  );
}

export default Home;
