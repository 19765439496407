import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './Home';
import Privacy from './Privacy';
import Agradecimento from './Obrigado';

function App(){
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/obrigado" component={Agradecimento} />
            </Switch>
        </Router>
    )
}

export default App;
