import React, {useState} from 'react';

import './style.scss';

import InputMask from 'react-input-mask';
import axios from 'axios';

const Formulario = (props) =>{
  const [form, setForm] = useState({
    name: null, 
    email: null, 
    tel: null, 
    cel: null,
    message: null,
  })
  const [errors, setErrors] = useState({});

  const [sending, setSending] = useState(false);

  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value,});
  }

  const validate = (form) => {
    let errors = {};
    if (!form.name) {
      errors.name = "* Preencha um nome válido"
    }
    if (!checkEmail(form.email)) {
      console.log('entrou')
      errors.email = '* Preencha um e-mail válido'
    }
    if (!form.tel) {
      errors.tel = "* Campo obrigatório"
    }
    if (!form.cel) {
      errors.cel = "* Campo obrigatório"
    }
    if (!form.message) {
      errors.message = "* Campo obrigatório"
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(form))

    setSending(true);

    const data = {
        from : "contato@simoplan.com.br",
        to : "contato@simoplan.com.br",
        html : `Contato enviado pelo site Altitude</br></br><b>Nome:</b> ${form.name}</br><b>E-mail:</b> ${form.email}</br><b>Telefone:</b> ${form.tel}</br><b>Celular:</b> ${form.cel}`,
        subject : "Contato enviado pelo site Altitude"
      }


      var formBody = [];
      for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

    const requestInfoDia = {
        method: 'POST',
        body: formBody,
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
    };

    const dadosDia = fetch('https://api.uziti.com.br/email', requestInfoDia);
    dadosDia.then(response => { 
        response.json().then((resp) => {
            if (resp.status.code === 200) {
              window.location.href = "/obrigado";
            } 
            setSending(false);
        })

    });

 
//Integração com Anapro

const PessoaTelefones = [];


if (form.tel) {
  const tel = form.tel.replace(/\D/g, "");
  const DDD = tel.substring(0, 2);
  const Numero = tel.substring(2, tel.length);
  PessoaTelefones.push({ DDD, Numero });
}
if (form.cel) {
  const tel = form.cel.replace(/\D/g, "");
  const DDD = tel.substring(0, 2);
  const Numero = tel.substring(2, tel.length);
  PessoaTelefones.push({ DDD, Numero });
}

const anaproSending = {
      Key: 'zvVpp4S9cWc1',
      CampanhaKey: 'aEPbqsUjhBg1',
      ProdutoKey: '42ZvWU_3XhE1',
      CanalKey: 'nzU7j44PT7k1',
      Midia: 'Site',
      Peca: '',
      PessoaNome: form.name,
      PessoaEmail: form.email,
      Observacoes: "Contato via site Altitude",
      KeyIntegradora: '29C8F816-6DE6-4E2F-BA64-A6F7A0B7ACAB',
      KeyAgencia: '68a51192-300a-402b-a444-38bfd1461b66',
      PessoaTelefones
}

//console.log(anaproSending);

  axios.post('https://crm.anapro.com.br/webcrm/webapi/integracao/CadastrarProspect', anaproSending).then(response => { 
    console.log(response);
  });

 
}

  const checkEmail = (email) => {
    return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email)
  }

    return(
       <form action="" className="form-content" onSubmit={e => handleSubmit(e)}>
          <div className="form-row">
            <div className="input-nome">
              <label htmlFor="name">Nome*</label>
              <input type="text" name="name" className="form-row--input" placeholder="Digite aqui o seu nome:" onChange={e => handleChange(e)} />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="input-email">
              <label htmlFor="email">E-mail*</label>
              <input type="text" name="email" className="form-row--input" placeholder="Digite aqui o seu e-mail" onChange={e => handleChange(e)} />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="input-telefone">
              <label htmlFor="tel">Telefone</label>
              <InputMask mask="(99) 9999-9999" type="text" name="tel" className="form-row--input" placeholder="(__)____-____" onChange={e => handleChange(e)}/>
              {errors.tel && <p className="error">{errors.tel}</p>}
            </div>
            <div className="input-celular">
              <label htmlFor="cel">Celular</label>
              <InputMask mask="(99) 99999-9999" name="cel" className="form-row--input" placeholder="(__)____-____" onChange={e => handleChange(e)}/>                            
              
              {errors.cel && <p className="error">{errors.cel}</p>}
            </div>
          </div>
          <button className="form-row--button">{sending ? 'ENVIANDO...' : 'CADASTRAR'}</button>
        </form>
    )
}

export default Formulario;;